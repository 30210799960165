import { localStorageHelper as storageHelper } from 'o365-modules';

let initialized = false;
function initEventListener() {
    if (initialized) { return; }
    document.body.addEventListener("o365-spellchecktoggle", onSpellcheckUpdate)
    initialized = true;
}

const spellcheckElements = new Map<string, HTMLElement>();

function onSpellcheckUpdate() {
    spellcheckElements.forEach(el => {
        const value = storageHelper.getItem("o365-spellcheck", { global: true });
        const toggledValue = value === 'true';
        el.setAttribute("spellcheck", `${toggledValue}`);
    })
}

const vSpellCheck = {
    mounted(el: HTMLElement) {
        if (el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement || el.isContentEditable) {
            const uid = crypto.randomUUID();
            el.dataset.spellcheckId = uid;
            const currentValue = storageHelper.getItem("o365-spellcheck", { global: true }, "false");
            el.setAttribute("spellcheck", currentValue);
            spellcheckElements.set(uid, el);
            initEventListener();
        }
    },
    beforeUnmount(el: HTMLElement) {
        if (el?.dataset?.spellcheckId) {
            spellcheckElements.delete(el.dataset.spellcheckId)
        }
    }
}

export default vSpellCheck;